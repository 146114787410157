import Image from "next/image";
import Link from "next/link";
import React from "react";

import {
  RiFacebookFill,
  RiLinkedinFill,
  RiXingFill,
  RiTwitterXFill,
} from "react-icons/ri";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="px-4 sm:px-6 2xl:px-0 py-8 border-t lg:rounded-tl-2xl lg:rounded-tr-2xl bg-slate-700 flex-col justify-center items-center gap-9 sm:gap-16 2xl:gap-[90px] flex">
        <div className="w-full max-w-[1440px] flex flex-col sm:grid sm:grid-cols-6 gap-6">
          <div className="flex flex-col gap-[17px] sm:col-span-6 lg:col-span-3 text-green">
            <Image
              src="/img/logo-white.svg"
              className=""
              width={70}
              height={43}
              alt="Website Logo"
            />
            <div className="text-slate-100 text-sm leading-tight max-w-[389px]">
              Empowering chess players with AI-driven insights and effortless
              game management.
            </div>
          </div>
          <div className="sm:col-span-3 lg:col-span-1 flex-col justify-start items-start gap-2 inline-flex opacity-90 text-slate-100 text-base font-normal leading-normal">
            <Link href="/">Product</Link>
            <Link href="/about_us">About Us</Link>
            <Link href="/pricing">Pricing</Link>
            <Link href="/contact_us">Contact Us</Link>
          </div>
          <div className="sm:col-span-3 lg:col-span-1 flex-col justify-start items-start gap-2 inline-flex opacity-90 text-slate-100 text-base font-normal leading-normal">
            <Link href="/terms_and_conditions">Terms</Link>
            <Link href="/privacy_policy">Privacy</Link>
          </div>
          <div className="sm:col-span-6 lg:col-span-1 flex lg:inline-flex justify-center lg:justify-between items-center lg:items-start gap-4 sm:gap-7 lg:gap-0 text-slate-100">
            <RiFacebookFill className="h-8 w-8" />
            <RiLinkedinFill className="h-8 w-8" />
            <RiTwitterXFill className="h-8 w-8" />
            <RiXingFill className="h-8 w-8" />
          </div>
        </div>
        <div className="text-slate-400 text-xs leading-none">
          © 2024 xchess.ai. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
