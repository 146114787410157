"use client";

import React from "react";
import Footer from "./Footer";
import clsx from "clsx";

export default function Wrapper({
  children,
  withFooter,
  fullHeightSection,
}: {
  children: React.ReactNode;
  withFooter?: boolean;
  fullHeightSection?: false;
}) {
  return (
    <nav
      id="layout-children-wrapper"
      className="flex flex-col duration-500 w-full min-h-[calc(100vh-theme(spacing.mobile-navbar))] sm:min-h-0"
    >
      <section className={clsx("w-full flex-1", fullHeightSection && "h-full")}>
        {children}
      </section>
      {withFooter && <Footer />}
    </nav>
  );
}
