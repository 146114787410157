import { User } from "@/prisma/schema/mysql";
import { AvatarProps } from "@radix-ui/react-avatar";

import { Avatar } from "@/components/ui/avatar";
import Image from "next/image";
import { UserCircleIcon } from "@heroicons/react/24/solid";

interface UserAvatarProps extends AvatarProps {
  user: Pick<User, "image" | "name">;
}

export function UserAvatar({ user, ...props }: UserAvatarProps) {
  if (!user) {
    return;
  }
  return (
    <Avatar {...props}>
      {user.image ? (
        <div className="relative aspect-square h-full w-full">
          <Image
            fill
            src={user.image}
            alt="profile picture"
            referrerPolicy="no-referrer"
          />
        </div>
      ) : (
        <UserCircleIcon className="w-10 h-10 text-brand-secondary" />
      )}
    </Avatar>
  );
}
