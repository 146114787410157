import { useConfig } from "@/components/providers/config-provider";
import { env, isClient } from "./utils";

export const getCdnUrl = (): string => {
  // For server components
  if (process.env.CDN_DISABLED === "true") {
    return "";
  }

  if (!isClient()) {
    return env("CDN_URL");
  }

  // For client components
  const { cdnUrl, cdnDisabled } = useConfig();
  if (cdnDisabled) {
    return "";
  }

  return cdnUrl || "";
};

export const getPublicAssetPath = (path: string): string => {
  // return path to asset in CDN or in local public folder

  if (!path) {
    throw new Error("Asset path is required");
  }

  // Handle special URL types
  if (path.match(/^(https?:\/\/|data:|blob:)/)) {
    return path;
  }

  // Handle protocol-relative URLs
  if (path.startsWith("//")) {
    return `https:${path}`;
  }

  // Normalize the path
  const normalizedPath = path.startsWith("/") ? path.slice(1) : path;

  // Use CDN
  const cdnUrl = getCdnUrl();

  if (!cdnUrl) {
    return `/${normalizedPath}`;
  }

  return `${cdnUrl}public/${normalizedPath}`;
};
